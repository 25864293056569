

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form as ElForm, Input } from 'element-ui';
@Component
export default class InsLogin extends Vue {
  targetUrl: string = '';
  username: string = '';
  password: string = '';
  remember: boolean = false;
  UPError: boolean = false;
  @Prop() private rollback!:Function;
  checkEmail (rule, value, callback) {
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    if (this.UPError) {
      callback(new Error(this.$t('Login.Loginerror')as string));
    }
    if (!value) {
      callback(new Error(this.$t('Login.EnterEmail')as string));
    }
    setTimeout(() => {
      if (mailReg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('Login.CorrectEmail')as string));
      }
    }, 100);
  }
   private ruleForm = {
     username: '',
     password: ''
   }
   get rules () {
     return {
       username: [{ required: true, validator: this.checkEmail, trigger: 'blur' }],
       password: [
         { required: true, message: this.$t('Login.LoginPwd'), trigger: 'blur' }
       ]

     };
   }
   private submitForm (formName) {
     let _this = this;
     (this.$refs.ruleForm as ElForm).validate((valid) => {
       if (valid) {
         this.$Api.member.login(this.ruleForm.username, this.ruleForm.password, true).then(
           function (response) {
             _this.$store.dispatch('doLogin');
             _this.$emit('succeed');
             _this.$LoginClose();
             //  if (_this.$route.query && _this.$route.query.returnurl) { _this.$router.push(_this.$route.query.returnurl as string); } else { _this.$router.push('/account/memberInfo'); }
           },
           function (response) {
             //  _this.$message({
             //    message: response.Message,
             //    type: 'error'
             //  });
             _this.UPError = true;
             (_this.$refs.ruleForm as ElForm).validate(() => { _this.UPError = false; });
           }
         );
       } else {
         console.log('error submit!!');
         return false;
       }
     });
   }
   onCancle () {
     this.$emit('close');
     Vue.prototype.root.$router.push('/account/register');
   }
   onForgetPassword () {
     this.$emit('close');
     Vue.prototype.root.$router.push('/account/forgetPassword');
   }
}
