













import { Component, Prop, Vue } from 'vue-property-decorator';
import Login from '@/components/business/mobile/login/InsLogin.vue';
import Auth from '@/sdk/common/Auth';
@Component({ components: { Login } })
export default class InsLoginFlow extends Vue {
  private show:boolean = false;
  private rollback : Function | undefined = undefined;
  private params: [] = [];
  public hidden () {
    this.show = false;
    document.body.style.overflowY = 'auto';
  }
  public showL () {
    Auth.GetToken().then(() => {
      this.show = true;
      setTimeout(() => { window.scrollTo(0, 0); document.body.style.overflowY = 'hidden'; }, 50);
    });
  }
  public setRollback (rollback, params) {
    this.rollback = rollback;
    this.params = params;
  }
  rb () {
    if (this.rollback instanceof Function) this.rollback(...this.params); this.hidden();
  }
}
